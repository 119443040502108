.scroll-to-top-container{
    position: fixed;
    bottom: 50px;
    right: 25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid var(--accent-subtle);
    background-color: var(--secondary-bg);
    box-shadow: 0 0 5px 3px;
    visibility:visible;
    pointer-events: all;
    opacity: 1;
    transition: all 0.2s;
    z-index: 3;

}

.scroll-to-top-container:hover{
    box-shadow: 0 2px 5px 3px;
    cursor: pointer;
}

.scroll-to-top-container::after{
    content: "";
    height: 15px;
    width: 15px;
    border-right: 5px solid var(--secondary-text);
    border-top: 5px solid var(--secondary-text);
    position: absolute;
    transform: rotateZ(-45deg);
    left: 15px;
    top: 20px;
    transition: all 0.2s;
}

.scroll-to-top-container:hover::after{
    top:15px;
}

.invisible{
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}