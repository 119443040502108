.theme-selector-button {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: var(--secondary-bg);
}

.theme-selector-button:hover {
    cursor: pointer;
    background-color: var(--primary-bg);
}

.theme-selector-button--icon *{
    stroke: var(--primary-text);
}