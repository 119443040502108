.wave {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: wave;
    animation-name: wave;
    display: inline-block;
    -webkit-transform-origin: 70% 70%;
    transform-origin: 70% 70%;
    z-index: -1;
    position: relative;
}

.text-center {
    text-align: center;
}

.about{
    display: flex;
}

.headshot {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    float: left;
    margin: 20px 10px 10px 5px;
    border: 3px solid var(--accent-subtle);
    shape-outside: margin-box;
    z-index: 1;
    position: relative;
    box-shadow: -2px 2px 10px 1px var(--accent-subtle);
}

.headshot.dark-theme{
    border: 3px solid var(--accent-subtle);
    box-shadow: -2px 2px 5px 3px var(--secondary-bg);
}

.about__links__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    justify-content: flex-end;
}
.about__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    border-left: 1px solid var(--accent-subtle);
    justify-content: center;
}

.link {
    margin: 10px 0;
    text-decoration: none;
    color: var(--primary-text);
    font-weight: 600;
}

.link:hover{
    color: var(--accent-mild);
}

@keyframes wave {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    10% {
        -webkit-transform: rotate(14deg);
        transform: rotate(14deg);
    }

    20% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }

    30% {
        -webkit-transform: rotate(14deg);
        transform: rotate(14deg);
    }

    40% {
        -webkit-transform: rotate(-4deg);
        transform: rotate(-4deg);
    }

    50% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    60% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@media screen and (max-width:812px) {
    .about__links__container{
        align-items: center;
        margin-left: 0;
    }
    .about__links {
        flex-direction: row;
        justify-content: center;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid var(--accent-subtle);
    }

    .link {
        margin: 10px 15px;
    }
    .about{
        flex-direction: column;
    }
}

@media screen and (min-width:812px) {
    .headshot {
        height: 200px;
        width: 200px;
    }
}