@import url('https://fonts.cdnfonts.com/css/open-dyslexic');

/* Light Theme */
:root {
  /* A light gray for the background */
  --primary-bg: #f0f0f0;
  /* Pure white for cards or modals */
  --secondary-bg: #ffffff;
  /* Almost black for primary text */
  --primary-text: #333333;
  /* A lighter gray for secondary text or subtitles */
  --secondary-text: #555555;
  /* A strong blue for accents, drawn from your original primary blue */
  --accent-strong: #0066cc;
  /* A mild green for highlights, drawn from your original secondary green */
  --accent-mild: #009966;
  /* A very light gray for borders or less important text */
  --accent-subtle: #cccccc;
  scroll-behavior: smooth;
}

/* Dark Theme (Original Portfolio colors) */
.dark-theme {
  --primary-bg: #343a40;
  --secondary-bg: #292929;
  --primary-text: #ffffff;
  --secondary-text: #cccccc;
  --accent-strong: #007BFF;
  --accent-mild: #8fd29f;
  --accent-subtle: #a5d1ff;
}

* {
  transition: background-color 0.3s ease, color 0.3s ease;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg);
  font-size: clamp(16px,2vmin,20px);
}

.dyslexic-mode {
  font-family: 'Open-Dyslexic', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: 3px solid var(--accent-mild);
}

/* Override when using mouse */
.using-mouse *:focus {
  outline: none;
}


*{
  color: var(--primary-text);
}

section{
  padding: 55px 0 0 0;
}

.section{
  text-align: left;
  padding: 0 20px;
  margin-bottom: 10px;
}



.section h2 {
  text-align: left;
  border-bottom: 1px solid var(--accent-subtle);
  line-height: 0.1em;
  margin: 10px 0 20px;
  padding-left: 10%;
}

.section h2 span {
  background: var(--primary-bg);
  padding: 0 5px;
}

b{
  color: var(--accent-mild);
}