.header-top {
    display: flex;
    justify-content: space-between;
    background-color: var(--secondary-bg);
    padding: 1rem;
    align-items: center;
    z-index: 2;
    position: relative;
}

.header__personal {
    color: var(--primary-text);
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.header__personal--name {
    font-weight: 700;
    font-size: larger;
}

.header__settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 50%;
    min-width: 25%;
    z-index: 2;
}

.header-sticky {
    box-sizing: border-box;
    color: var(--primary-text);
    width: 100%;
    height: 50px;
    padding: 12px 0;
    top: 0;
    background-color: var(--secondary-bg);
    border-bottom: 1px solid var(--accent-subtle);
    z-index: 2;
}

.p-fixed {
    position: fixed;
}

.dummy-div {
    height: 50px;
}

.d-none {
    display: none;
}

.header__settings__lang-selector{
    margin-left: 20px;
}

@media screen and (min-width: 812px) {
    .header__personal {
        display: flex;
        flex-direction: row-reverse;
        justify-content:space-between;
        align-items: center;
        min-width: 50%;
    }
    .header__personal--name {
        font-weight: 700;
        font-size: xx-large;
    }
}

/* Adjustments for smaller screens */
@media screen and (max-width: 811px) {
    .header-top{
        align-items: center;
        
        
    }
    .header__personal {
        flex-direction: column; 
        align-items: flex-start; 
        
    }

    .header__personal--name {
        font-size: larger;
        margin-bottom: 0.5rem; 
    }

    .header__settings {
        flex-direction: column; 
        gap: 0.5rem;

    }

    .header__settings__theme-selector{
        display: flex;
        justify-content: flex-end;
    }

    .header__settings__lang-selector{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .header__settings > * {
        width: 100%;
        /* margin-bottom: 0.5rem; */
    }
}
/* 
@media screen and (max-width: 811px) {
    .header-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .header__personal {
        flex: 1 1 auto;
        align-items: flex-start;
        padding-right: 10px;
    }

    .header__personal--name {
        font-size: larger;
        margin-bottom: 0.5rem;
    }

    .header__settings {
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }

    .header__settings__theme-selector,
    .header__settings__dyslexia-selector,
    .header__settings__lang-selector {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0; 
    }
} */
