.tech-stack{
    padding-top: 10px;
}
.tech-stack__stack{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tech-stack__stack__substack{
    display: flex;
    justify-content: flex-start;
    gap:10px;
}

@media screen and (min-width: 812px) {
    .tech-stack__stack{
        flex-direction: row;
        gap: 30px;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}