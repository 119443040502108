.footer{
    margin-top: 75px;
    border-top: 1px solid var(--accent-subtle);
    padding: 15px 30px;
    background-color: var(--secondary-bg);
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 475px) {
    .footer{
        flex-direction: column;
        align-items: center;
    }
}