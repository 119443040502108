.project-card {
    box-sizing: border-box;
    background-color: var(--secondary-bg);
    /* margin: 0 30px; */
    box-shadow: 5px 5px 15px 1px hsla(0, 0%, 0%, 0.35);
    border: 4px solid var(--accent-subtle);
    border-radius: 12px;
    max-width: 500px;
}

.project-card.dark-theme {
    border: 4px solid #cccccc;
}

.project-card.dark-theme .project-card__title {
    background-color: #cccccc;
    color: var(--secondary-bg);
}

.project-card__title {
    text-align: center;
    background-color: var(--accent-subtle);
    width: 100%;
    font-size: 2rem;
    font-weight: 800;
}

.project-card__img{
    display: flex;
    justify-content: center;
}

.project-card__img img {
    object-fit: contain;
    max-height: 276.75px;
    width: 492px;
}

.project-card__details {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.project-card__footer {
    display: flex;
    justify-content: space-between;
}

.project-card__footer__github-link {
    display: flex;
    flex-direction: column;
}

.project-card__footer__deployment-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 400px) {
    .project-card {
        max-width: 300px;
    }

    .project-card__img img {
        width: 292px;
    }
}
@media screen and (min-width: 400.1px) and (max-width: 530px) {
    .project-card {
        max-width: 375px;
    }

    .project-card__img img {
        width: 368px;
    }
}

@media screen and (min-width:812px) {
    .project-card {
        max-width: 800px;
    }

    .project-card__img img {
        object-fit: contain;
        max-height: 444.5px;
        width: 792px;
    }
}